<template>
  <div class="container">
    <LayoutViewWrapperNarrow>
      <component :is="ContentComponent" />
    </LayoutViewWrapperNarrow>
    <NavMobile v-if="isMobile" :isLoginFirst="true" />
  </div>
</template>

<script>
import { computed, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";
import useIsMobile from "@/composables/useIsMobile";

import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import SvgIllustrationsTick from "@/components/Svg/Illustrations/SvgIllustrationsTick.vue";
import NavMobile from "@/components/NavMobile.vue";
import usePasswordReset from "@/composables/usePasswordReset";

export default {
  components: {
    LayoutViewWrapperNarrow,
    BaseH1,
    TextDescription,
    SvgIllustrationsTick,
    NavMobile,
  },

  setup() {
    const { isMobile } = useIsMobile();
    const { ERROR_CODE } = usePasswordReset();
    const route = useRoute();

    const ContentComponent = computed(() => {
      const errorCode = route.query.reason;
      switch (errorCode) {
        case ERROR_CODE.WRONG_KEY:
          return defineAsyncComponent(() => {
            return import(
              "@/views/Auth/PasswordReset/AuthPasswordResetErrorWrongKey.vue"
            );
          });

        case ERROR_CODE.LINK_EXPIRED:
          return defineAsyncComponent(() => {
            return import(
              "@/views/Auth/PasswordReset/AuthPasswordResetErrorLinkExpired.vue"
            );
          });

        default:
          return defineAsyncComponent(() => {
            return import(
              "@/views/Auth/PasswordReset/AuthPasswordResetErrorWrongKey.vue"
            );
          });
      }
    });

    return {
      ContentComponent,
      isMobile,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 2 * var(--header-default--height));
}
@media (max-width: 1200px) {
  .container {
    min-height: inherit;
  }
}
</style>
